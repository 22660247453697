import { useGlobalContext } from "../../AppProvider";
import Phone3 from "../../assets/img/phone-3.png";
import DownloadApp from "../../components/DownloadApp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function DownloadSection() {
  const { Container } = useGlobalContext();
  return (
    <div
      id="download_app"
      className="flex max-h-max md:max-h-[900px] items-center bg-inherit md:m-0 md:min-h-[650px] my-[30px]"
    >
      <div className="h-max w-full flex items-center bg-grey-containerColor md:h-2/3 max-h-max">
        <Container>
          <div className="flex items-center justify-between md:h-[500px] gap-20 flex-col md:flex-row md:gap-4 m-6">
            <div
              className="gap-12 flex flex-1 flex-col md:gap-12"
              data-aos="fade-down"
            >
              <h1 className="text-3xl font-bold lg:text-4xl  ">
                Download the AXIX app, and experience the power of one
              </h1>

              <DownloadApp />
            </div>

            <div
              className="flex flex-1 h-5/6 "
              data-aos="fade-down"
              data-aos-duration="3000"
            >
              <LazyLoadImage
                effect="blur"
                src={Phone3}
                alt=""
                className="object-cover"
              />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
