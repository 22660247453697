import { Route, Routes } from 'react-router';
import Header from './components/Header';
import About from './pages/About';
import Contact from './pages/Contact';
import Drive from './pages/Drive';
import Error from './pages/Error';
import FAQ from './pages/FAQ';
import Home from './pages/Home';
import 'swiper/css/bundle';
import Footer from './components/Footer';
import Privacy from './pages/PolicyMain';
import Policy from './pages/Policy';
import BackToTopButton from './components/BackToTop';
import BubbleAnimation from './components/BubbleAnimation';
import AOS from 'aos';
import 'aos/dist/aos.css';
import CustomCursor from './components/CustomCursor/CustomCursor';

AOS.init({ duration: 700 });

function App() {
  return (
    <div className='app bg-black-bodyBgColor text-white-50 min-w-screen min-h-screen flex flex-col'>
      <div className='z-50'>
        <Header />
        <div className='pt-28'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/drive' element={<Drive />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/faq' element={<FAQ />} />
            <Route path='/policies' element={<Policy />} />
            <Route path='/privacy/policy' element={<Privacy />} />

            <Route path='*' element={<Error />} />
          </Routes>
        </div>
      </div>
      <BackToTopButton />
      <BubbleAnimation />
      <Footer />
    </div>
  );
}

export default App;
