import { useGlobalContext } from "../../AppProvider";
import Drive1 from "../../assets/img/drive/1.png";
import Drive2 from "../../assets/img/drive/2.png";
import Drive3 from "../../assets/img/drive/3.png";
import Drive4 from "../../assets/img/drive/4.png";
import DownloadAppPartner from "../../components/DownloadApp/index-2.js";

export default function Hero() {
  const { Container } = useGlobalContext();
  return (
    <section className="bg-black-bodyBgColor custom-dividend-height grid">
      <Container>
        <div
          className="flex h-full gap-20 justify-between items-center flex-col  md:flex-row lg:gap-40 py-8"
          data-aos="zoom-in"
        >
          <div className="grid gap-12">
            <h1 className="font-bold text-5xl">Drive With Us.</h1>
            <p className="text-3xl font-bold">
              Build wealth and a steady income by joining AXIX as a Partner.
            </p>
            <div>
              <p>Download the App today</p>
              <DownloadAppPartner />
            </div>
          </div>

          <div className="flex gap-6 md:min-w-[564px] md:p-20 p-8">
            <div className="flex flex-col gap-6 min-w-1/2">
              <div>
                <img src={Drive1} alt="" />
              </div>
              <div>
                <img src={Drive2} alt="" />
              </div>
            </div>
            <div className="flex flex-col gap-6">
              <div>
                <img src={Drive3} alt="" />
              </div>
              <div>
                <img src={Drive4} alt="" />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
