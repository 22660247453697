import React from 'react';
import Benefits from './Benefits';
import Hero from './Hero';

function Drive() {
  return (
    <div>
      <Hero />
      <Benefits />
    </div>
  );
}

export default Drive;
