import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useGlobalContext } from '../../AppProvider';
import { Link } from 'react-router-dom';
import Landing2 from '../../assets/img/landing-showcase-2-liner.png';
import UniquePortrait from '../../assets/img/unique-portrait.png';
import { AiOutlineArrowRight } from 'react-icons/ai';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function UniquePortray() {
  const { Container } = useGlobalContext();
  return (
    <div className='flex items-center justify-center bg-grey-containerColor custom-dividend-height lg:px-12'>
      <Container>
        <div
          className='rounded-3xl  flex flex-col w-full gap-12 bg-orange-primaryColor px-12 py-12  items-center md:flex-row lg:py-4 lg:h-[30rem]'
          data-aos='fade-up'
        >
          <div className=''>
            <LazyLoadImage
              effect='blur'
              src={UniquePortrait}
              alt='showing-our-uniqueness'
            />
          </div>

          <div className='flex flex-col gap-6 '>
            <h1 className='text-4xl font-bold lg:text-5xl '>
              We meet your unique needs
            </h1>
            <p>
              While being able to make multiple logistic services requests, we
              prioritize the safety & satisfaction of all of our partners and
              clients.
            </p>
            <Link to={'/faq'}>
              <span className='flex items-center gap-2 font-bold'>
                Learn more <AiOutlineArrowRight className='mt-[3px]' />
              </span>
            </Link>
          </div>

          <div className='hidden md:block lg:scale-75'>
            <img src={Landing2} alt='' />
          </div>
        </div>
      </Container>
    </div>
  );
}
