import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const PrimaryButton = styled.button`
  border-radius: 5px;
  background: ${colors.orange.primaryColor};
  padding: 1rem 2rem;
  font-weight: bold;
  text-align: center;
  position: relative;
  color: #fff;
  font-size: 1rem;
  transition: all 0.3s;
  letter-spacing: 0.15rem;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    z-index: -2;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: ${colors.orange.secondaryColor};
    transition: all 0.3s;
    border-radius: 3px;
    z-index: -1;
  }

  &:focus {
    scale: 0.8;
  }

  &:hover {
    background-color: #fff;
    &:before {
      width: 100%;
    }
  }
  ${(props) => props.css || ''}
`;

export const CoverButton = styled.button`
  background-color: ${colors.white[50]};
  padding: 1rem;
  cursor: pointer;
  position: relative;
  border-radius: 50%;

  & > * {
    position: absolute;
    z-index: 20;
    top: 25%;
    left: 25%;
    transition: 200ms all ease-in;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    transition: 200ms all ease;
    z-index: 2;
  }

  &:hover > * {
    color: white;
  }

  &:hover::after {
    box-shadow: inset 0 3px ${colors.orange.primaryColor},
      inset 0px 35px ${colors.orange.secondaryColor},
      inset 100px 50px transparent;
  }
`;
