const landLogisticsOptions = [
  {
    id: 1,
    heading: `The Power of One`,
    paragraph: `You don’t have to download
multiple apps on your mobile device to enjoy the best services. Just get AXIX.`,
  },
  {
    id: 2,
    heading: `Multiple Requests`,
    paragraph: `Never feel stuck! You can make more than one request of a logistics service you need at the same time.`,
  },
  {
    id: 3,
    heading: `Easy Tracking`,
    paragraph: `Your package is tracked real time so you always know where it is.`,
  },
  {
    id: 4,
    heading: `Wallet System`,
    paragraph: `Always have access to fast payment even if your bank network is down or you do not have cash at hand.`,
  },
  {
    id: 5,
    heading: `In-app Calls & Texts`,
    paragraph: `If you do not have airtime, you can make clean clear calls and send messages to communicate easily with the service provider.`,
  },
  {
    id: 6,
    heading: `SOS Functionality`,
    paragraph: `You think you are in danger? Nothing to worry as you have access to law enforcement with just a click on the SOS button.`,
  },
];

export default landLogisticsOptions;
