const colors = {
  white: {
    50: '#FFFFFF',
    100: '#F69100',
  },

  orange: {
    primaryColor: '#F69100',
    secondaryColor: '#FF6B00',
    800: '#241a0b',
  },

  grey: {
    containerColor: '#1C1C1E',
    50: '#D9D9D9',
    100: '#1C1C1E',
  },

  black: {
    bodyBgColor: '#141414',
    100: '#1a1a1a',
  },
};

export default colors;
