import { useGlobalContext } from '../../AppProvider';
import { Link } from 'react-router-dom';
import { CgArrowLongRight } from 'react-icons/cg';
import landLogisticsOptions from '../../assets/data/landLogisticsOptions';

export default function Services() {
  const { Container } = useGlobalContext();

  const Article = ({ heading, paragraph }) => {
    return (
      <article
        data-aos='fade-down'
        className='flex flex-col basis-80 grow  gap-1 shrink'
      >
        <h3 className='text-2xl font-semibold text-center md:text-left'>
          {heading}
        </h3>
        <p className='text-center md:text-left'>{paragraph}</p>
      </article>
    );
  };

  return (
    <div className='flex min-h-[750px] bg-grey-containerColor lg:h-[950px]'>
      <div className='flex flex-col items-center justify-center gap-3 w-full mb-12'>
        <div className='  border-t-[1px] border-grey-50 w-full'>
          <Container>
            <div className='flex justify-around items-center md:flex-row flex-col gap-3 h-4/5'>
              <Link to={'/drive'} data-aos='fade-right'>
                <button className='bg-white-50 text-black-bodyBgColor rounded-lg flex items-center justify-center gap-4 p-2 md:w-1/2 min-w-[12rem] md:py-3 md:px-8 lg:w-64'>
                  <span>Drive with us</span>
                  <span className='text-2xl mt-1 hidden md:block'>
                    <CgArrowLongRight />
                  </span>
                </button>
              </Link>

              <Link to={'/drive'} data-aos='fade-left'>
                <button className='bg-white-50 text-black-bodyBgColor px-8 rounded-lg flex items-center justify-center gap-4 p-2 md:w-1/2 min-w-[12rem] md:ml-5 p-2 md:py-3 md:px-8 lg:m-0 lg:w-64 '>
                  <span>Own a fleet?</span>
                  <span className='text-2xl mt-1 hidden md:block'>
                    <CgArrowLongRight />
                  </span>
                </button>
              </Link>
            </div>
          </Container>
        </div>

        <div className='border-y-[1px] border-grey-50 min-h-4/5 w-full lg:h-4/5 py-12'>
          <Container>
            <div className='flex flex-col gap-20 items-center my-10'>
              <h1 className='text-3xl font-bold lg:text-4xl text-center max-w-[36ch] '>
                We have a variety of land logistics service options tailor-made
                for you
              </h1>

              <div className='flex gap-14 justify-center flex-wrap '>
                {landLogisticsOptions.map((option) => (
                  <Article {...option} />
                ))}
              </div>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}
