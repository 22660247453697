const faqList = [
  {
    id: 1,
    question: 'What is AXIX?',
    answer:
      'AXIX app gives you fast access to all forms of land logistics services. From delivery bike, taxis to towing trucks and ambulances.',
  },
  {
    id: 2,
    question: 'Where is it available?',
    answer: 'On Android and iOS stores.',
  },
  {
    id: 3,
    question: 'What is the pricing for a trip?',
    answer:
      'Prices are automatically calculated using intelligent systems. Each logistics option has its unique pricing system and is calculated using distance and the time it takes to complete the service.',
  },
  {
    id: 4,
    question: 'How to request multiple trips on the AXIX app?',
    answer:
      'Before you request the service option, click on the multiple request button. Once a request is made, AXIX will ask for the other service you want to request while the other works in the background',
  },
  {
    id: 5,
    question: 'Can I order all services at once?',
    answer:
      'YES! That is one of our many unique qualities. Maximum of 4 service options can run simultaneously.',
  },
  {
    id: 6,
    question: 'Can I pre-book?',
    answer:
      ' YES. AXIX has a service scheduling feature were you can pre-book.',
  },
  {
    id: 7,
    question: 'Can I order AXIX from anywhere in my city?',
    answer:
      'AXIX is currently only available in Abuja but AXIX is coming to other cities very soon. ',
  },
  {
    id: 11,
    question: 'How can I tip my AXIX driver?',
    answer: 'We encourage tips, kindly do so in cash or bank transfer. ',
  },
  {
    id: 12,
    question: 'How can I change my payment details?',
    answer:
      'Go to your profile, click on edit and select payment option. Edit accordingly.',
  },
  {
    id: 13,
    question: 'How can I contact AXIX support?',
    answer:
      'Kindly contact support via the contact details and/or form provided on the contact page. We respond within 24hrs. ',
  },
  {
    id: 14,
    question: 'What should I do if I leave something in the car?',
    answer:
      'You are responsible for the safe keeping of your items, however, if something is left, kindly call the service provider’s number immediately. We can provide support to reach the service provider only.',
  },
  {
    id: 15,
    question: 'How can I share my arrival time with my friends?',
    answer:
      'Click on share my location button and you can share with friends and family.',
  },
  {
    id: 16,
    question: 'Who pays for waiting on AXIX?',
    answer: 'The client pays for waiting.',
  },
  {
    id: 17,
    question: 'Does AXIX charge per person or per ride?',
    answer: 'Charges are made per service.',
  },
];

export default faqList;
