import PrivacyPolicy from '../../assets/pdfs/PRIVACY POLICY.pdf';
import FeedbackPolicy from '../../assets/pdfs/FEEDBACK POLICY.pdf';
import EmergencyPolicy from '../../assets/pdfs/EMERGENCY RESPONSE POLICY.pdf';
import NonDiscrimincationPolicy from '../../assets/pdfs/NON DISCRIMINATION POLICY.pdf';
import AlcoholHardSubstancesPolicy from '../../assets/pdfs/ALCOHOL AND HARD SUBSTANCES PROHIBITION POLICY.pdf';
import FirearmsPolicy from '../../assets/pdfs/FIREARMS AND DANGEROUS WEAPONS PROHIBITION POLICY.pdf';
import IntellectualPropertyPolicy from '../../assets/pdfs/INTELLECTUAL PROPERTY PROTECTION POLICY.pdf';

const policiesList = [
  {
    id: 1,
    question: 'FEEDBACK POLICY',
    answer: `White Axis appreciates your feedback as we always love to know your opinion on our
services in order to serve you better. Where you consider an information confidential,
you are not under any obligation to share when giving your feedback. Where however,
you share such information, suggestion or idea, regardless of what your communication
or your submissions says, you understand that your submissions are voluntary and will
automatically become the property of White Axis, without any compensation to you.
You also understand that White Axis has no obligation to review your submissions or
keep them confidential.
White Axis reserves the right to implement and distribute your submissions and their
contents for any purpose in any way, without any compensation to you.`,
    link: FeedbackPolicy,
  },
  {
    id: 2,
    question: 'EMERGENCY RESPONSE POLICY',
    answer: `Security and safety of all Users on our platform is of paramount importance to us. .
To ensure safety is maintained, we have put measures in place for information to be
shared with the relevant authorities in the event of an emergency
In the event of an emergency or danger, Users on the platform may use the SOS
button which sends out a signal to law enforcement agencies
Where necessary, White Axis would also share your trip details with the Nigerian
Police. Trip details that we will share are your estimated current location at the time
your call was placed, the car’s make, model, colour, and license plate, your name and
phone number, your pickup and drop off locations, and your driver’s name.
The location that we will share with the police is your latitude and longitude, as
determined by your device’s operating system at the time that you placed the call.
Your device’s location information may not be accurate, particularly in dense
urban environments.
You may also decide to share your location and other trip details information with
friends and family.`,
    link: EmergencyPolicy,
  },
  {
    id: 3,
    question: 'NON-DISCRIMINATION POLICY',
    answer: `White Axis does not discriminate and will not permit any discrimination against any
User irrespective of race, religion, nationality, origin, disability, sex, marital status, age
or any other characteristic protected under applicable law. Such discrimination
includes, but is not limited to; any user refusing to provide or accept services based
on any of these characteristics. Any user found to have violated this prohibition may
lose access to the AXIX platform.`,
    link: NonDiscrimincationPolicy,
  },
  {
    id: 4,
    question: 'ALCOHOL AND HARD SUBSTANCES PROHIBITION POLICY',
    answer: `White Axis does not tolerate the use of alcohol or hard drugs by Users of the app.
Where you reasonably suspect that a Partner may be under the influence of drugs or
alcohol, please ask the driver to END THE TRIP IMMEDIATELY and send a report to
support@whiteaxis.ai or contact us on 08081362116 `,
    link: AlcoholHardSubstancesPolicy,
  },
  {
    id: 5,
    question: 'FIREARMS AND DANGEROUS WEAPONS PROHIBITION POLICY',
    answer: `White Axis is committed to ensuring to the best of its ability that your trips are safe,
secure and reliable. As part of our policies to ensure safety, users are prohibited
from carrying firearms or dangerous weapons of any kind while using our app. White
Axis has the right to take any decision including barring access of the User to his/her
account in the event firearms or dangerous weapons are found in his/her
possession. `,
    link: FirearmsPolicy,
  },
  {
    id: 7,
    question: 'INTELLECTUAL PROPERTY PROTECTION POLICY',
    answer: `White Axis and AXIX are trademarked and Copyrighted under the laws of Nigeria.
Where an account holder infringes on the Intellectual Property rights of White Axis, it
can in its sole discretion decide to terminate the account of the user where the user
repeatedly infringes on the copyrights or other intellectual property rights of others.`,
    link: IntellectualPropertyPolicy,
  },
];

export default policiesList;
