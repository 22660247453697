import React from 'react';
import { Link } from 'react-router-dom';
import FaqHero from '../assets/img/about-hero-prev.png';

const PolicyMain = () => {
  const ShowCaseOne = () => {
    return (
      <div className='flex flex-col'>
        <div className='flex'>
          <img
            src={FaqHero}
            className='min-h-[250px] xl:w-full max-h-[550px] object-cover'
            alt=''
          />
        </div>
        <div className='flex relative'>
          <div className='container mx-auto px-8 lg:px-20'>
            <h1 className='absolute md:text-6xl text-4xl font-bold -top-20 md:-top-28'>
              Privacy Policy
            </h1>
          </div>
        </div>
      </div>
    );
  };

  const ShowCaseTwo = () => {
    return (

      <div className='custom-faq-bg-lg min-h-[52rem] flex items-center justify-center py-32'>
        <div className='bg-grey-100 lg:w-1/2 w-5/6 p-8 rounded-xl flex flex-col gap-12'>
          <h4 className='text-2xl font-bold '>Privacy Policy</h4>
          <div className='flex flex-col gap-6 '>
               <p><b>WHITE AXIS LIMITED</b> with <b>RC Number 1730098</b> and <b>Registered Address at No 12
                Cairo Crescent Wuse 2 Abuja, Nigeria</b>, is the controller of personal data of Clients. The term "us" or "we" refers to the owner of the White Axis app, 
                White Axis Limited, a private limited Company, registered under the Laws of Nigeria.</p>
                <p>When you use White Axis, you trust us with your personal data. Protecting your personal data is very important to us. By visiting our website, you provide us with your personal information. 
                  This policy explains the data we collect, how we use the data and the legal basis of the same.
                </p>
                
          </div>

          <div className='flex flex-col gap-6 '>
               <h5>1. APPLICATION</h5>
               <p style={{'padding-left':'30px'}}>
                This notice applies to:
                </p>
                <p style={{'padding-left':'30px'}}>
                  <strong>Partners</strong>: Those who provide logistics and transportation services to Clients via the AXIX app
                </p>
                <p style={{'padding-left':'30px'}}>
                  <strong>Clients</strong>: Those who request or receive transportation or logistics services via the AXIX app.
                  <br/>Together, Partners and Clients are referred to as "Users"
                </p>
          </div>

          <div className='flex flex-col gap-6 '>
               <h5>2. THE DATA/INFORMATION WE COLLECT</h5>
               <p style={{'padding-left':'30px'}}>
                  <ul style={{'list-style-type': 'square', 'list-style-color':'white'}}>
                    <li>Contact details: These include name, e-mail, phone, place of residence.</li>
                    <li>Geo location, driving routes, time and the destination of a trip.</li>
                    <li>Profile information such as a valid ID, your picture, language preference, vehicle registration number e.t.c</li>
                    <li>Payment information such as the payment card used and amount charged to card</li>
                    <li>Information about disputes.</li>
                    <li>Information about vehicles.</li>
                    <li>Partners efficiency and ratings.</li>
                    <li>Partners license, photo, profession and identity documents.</li>
                    <li>Data of the device on which the White Axis app has been installed.</li>
                    <li>Demographic data</li>
                    <li>Transaction data related to the use of our services including the type of services requested or provided, 
                      trip or order details and payment transaction information</li>
                    <li>Communication and correspondence records such as when the Partner engages with our in-app chat or speaks 
                      with our customer service agents, the date and time of the communication and its content</li>
                    <li>Usage date: We collect data on how users interact with our services</li>
                    <li>Data is collected from Users for participating in our referral programs</li>
                    <li>User content: we collect data including chat logs and call recordings when users contact our customer support, provide ratings or feedback.</li>
                  </ul>
                </p>
                
          </div>

          <div className='flex flex-col gap-6 '>
               <h5>3. WHY WE COLLECT YOUR DATA/INFORMATION</h5>
               <p style={{'padding-left':'30px'}}>
               Broadly, we collect your data for the following reasons:
                  <ul style={{'list-style-type': 'square', 'list-style-color':'white'}}>
                    <li>To enable ease of communication between users</li>
                    <li>To enhance the safety and security of our users</li>
                    <li>For marketing and advertising to inform users of our services and features</li>
                    <li>In connection with legal disputes</li>
                    <li>For customer support</li>
                    <li>For research and development</li>
                    <li>For sending non-marketing communication to users</li>
                    <li>To provide, personalize, maintain and improve our services</li>
                  </ul>
                </p>
                <p style={{'padding-left':'30px'}}>Below is a breakdown of the information we collect and why we collect them:
</p>
                <p style={{'padding-left':'30px'}}>Your personal information is collected to connect Partners with Clients on the platform so they can be picked and dropped off; or to show where our nearest available vehicles are located.
Geolocation data is collected and displayed to enable efficient pick up and drop off, to resolve quality issues to our services, to appropriately bill you and keep track of our Partners. The collection of geolocation date stops after closing the AXIX app
Geolocation data is collected to analyse geographical routes and give suggestions to Partners.
Partners License and identity documents are processed to determine compliance with legal requirements.
We use data collected for analysis, machine learning, product development, research and testing. This helps us make our services more convenient and easy to use and to improve our services and features.
The partners photo, name and vehicle details is displayed to Clients to help them identify the Partner and vehicle
Your personal information such as your name, phone number, email address and means of identification is collected to give Users updates on the trip; how it is going and when it is completed. We also collect your personal information in order to enable us send you receipts and confirmation slips when the trip is completed. 
Your personal information such as your Means of Identification, NIN and BVN is collected to ensure you are protected through our authentication and verification checks
Your personal information is collected in order to provide customer support and to notify you on updates and feedback.
Data on trip routes is collected to enable us analyse geographic coverage and to help us improve recommendations to Partners about the most efficient routes
Your payment details are obtained to process payment and ensure a smooth transaction between Users of the app
Data on progress and status of your trip such as feedback and ratings is collected to ensure you enjoy a seamless trip and to encourage User safety 
Data on status of your trip is collected to resolve disputes and service quality issues
Your personal information is collected to send direct marketing messages. This is however subject to permission and approval by you. 
Geolocation and driving routes are processed to analyse the geographical area and give suggestions to the Partners about most efficient routes.  Where you, as a Partner, choose not to disclose your geolocation for Clients, you must close the app or indicate in the app that you are offline and currently are not providing transportation services.
Partners license, profession, identity documents are processed to determine the compliance with the legal requirements and the suitability of pursuing a profession as a Partner.
The Partner’s photo, name and vehicle details for the users to identify the Partner and vehicle. 
Payment details are obtained to process Clients' payment on behalf of Partners for transportation services.
</p>
          </div>

          

          <div className='flex flex-col gap-6 '>
               <h5>4. LEGAL BASIS</h5>
               <p style={{'padding-left':'30px'}}>
                 Personal data is processed to ensure the performance of the contract between the Partner and the Client
Personal data may in some instances be accessed for the purpose of investigating and detecting fraudulent payments.
Data is processed in order to comply with a legal obligation such as processing data when the law requires it 
Data is processed to protect the interest of others especially in cases of emergency or threat to life
  When you agree to your personal information and geolocation being accessed, you give us the right to collect and process the data submitted. 
You have given us consent to do so for any other specific purpose for which you have been duly informed.   

                </p>
                
          </div>

          <div className='flex flex-col gap-6 '>
               <h5>5. WHO HAS YOUR INFORMATION</h5>
               <p style={{'padding-left':'30px'}}>
                  The Client has the personal information of a Partner after an order has been accepted and receipt sent to the Partner by us. The Client would be able to see the Partners name, vehicle, phone number, photo and geolocation data. This will only be accessible by the Client when he/she activates the app
The Partner has the personal information of the Client when they engage in a ride on the app; the Partner will see the name, phone number and geolocation data of the Client
The general public where questions or comments from users submitted through public forums may be viewable by the public, including any personal data included in the questions or comments submitted by a user.
Staff, members, affiliates, agents, business partners, subsidiaries or representatives of White Axis Limited. The information received would however be viewed and accessed subject to conditions as established in this Privacy Policy.
External recipients may have your information in special circumstances such as a legal proceedings or for the purpose of investigations. We however assure you that the disclosure will be done within the lawful boundaries. 

                </p>
                
          </div>

          <div className='flex flex-col gap-6 '>
               <h5>6. RIGHTS OF THE CLIENT</h5>
               <p style={{'padding-left':'30px'}}>
                  <ul style={{'list-style-type': 'square', 'list-style-color':'white'}}>
                    <li>Right to ask us to restrict the processing of your information in certain circumstances. This however does not extend to legal claims and court proceedings</li>
                    <li>Right to object to processing of your information</li>
                    <li>Right to access your personal data on the app and request for copies of your personal information</li>
                    <li>Right to ask us to rectify any information you consider inaccurate</li>
                    <li>Right to ask us to erase your information in certain circumstances</li>
                  </ul>
                </p>
                
          </div>

          <div className='flex flex-col gap-6 '>
               <h5>7. RESTRICTION ON ACCESS TO PERSONAL INFORMATION /DATA</h5>
               <p style={{'padding-left':'30px'}}>
               Partners/Clients are not permitted to process the personal data of others on the app without express permission from us.
You are only permitted to access data for the purposes of fulfilling the transportation services.
You are not permitted to  collect, record, store, grant access, use or cross-use the personal data provided or accessible to you via the app for any reason other than making or receiving an order for transportation services
A violation of the above stated rules could lead to termination of your account and/or seeking redress in the Courts of law 

                </p>
                
          </div>

          <div className='flex flex-col gap-6 '>
               <h5>8. SECURITY</h5>
               <p style={{'padding-left':'30px'}}>
                <ul style={{'list-style-type': 'square', 'list-style-color':'white'}}>
                <li>Any personal data or information collected in the course of providing services is transferred to and stored in AWS cloud computing company.</li>
                <li>White Axis staff and affiliates can access personal data to the extent necessary to provide customer support.</li>

                </ul>
              </p>
          </div>

          <div className='flex flex-col gap-6 '>
               <h5>9. PROCESSING CLIENTS PERSONAL DATA</h5>
               <p style={{'padding-left':'30px'}}>
               As a Partner, you are not permitted to process the personal data of Clients without first obtaining permission from us. You may not contact any Client, collect, record, store, grant access, use or cross-use personal data provided by the Client or accessible to you via the app for any reason other than for the purpose of fulfilling transportation services.
               
              </p>
          </div>

          <div className='flex flex-col gap-6 '>
               <h5>10. CHANGES AND RETENTION</h5>
               <p style={{'padding-left':'30px'}}>
               <ul style={{'list-style-type': 'square', 'list-style-color':'white'}}>
                  <li> Your personal data can be retained for different periods of time depending on the type of data and purpose for which the data was collected. </li>
                  <li>Personal data can be viewed and corrected on the Partners Portal. </li>
                  <li>Changes cannot however be arbitrarily made to personal data. In order to make changes and edits, the Partner is to contact support@whiteaxis.ng  and directions would be provided.  </li>
                  <li>Your personal data will be stored as long as you have an active account with us. If your account is closed, personal data will be deleted after 5 years (according to the policies set out in this section) from the databases, unless such data is required to be retained for accounting, dispute resolution or fraud prevention purposes.</li>
                  <li>Your personal data will be stored for 7 years for accounting purposes and 10 years where there is a suspicion of administrative or criminal offence.</li>

                  </ul>
                  In the event of a dispute, your data shall be retained until the claim is satisfied or the expiry date of such claims
Recorded calls will be stored for a period of 1 year.
                </p>
                
          </div>

          <div className='flex flex-col gap-6 '>
               <h5>11. DISPUTE RESOLUTION</h5>
               <p style={{'padding-left':'30px'}}>
               Disputes relating to the processing of personal data are resolved through customer support support@whiteaxis.ai
               
              </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <ShowCaseOne />
      <ShowCaseTwo />
      <div className='flex flex-col gap-12 items-center my-20 '>
        {/* add link to playstore or appstore */}
        <Link to='/'>
          <span className='p-4 bg-orange-primaryColor rounded '>
            {'Sign Up with AXIX'}
          </span>
        </Link>
        <p className='font-bold text-lg'>
          {'Got questions or concerns? '}
          <Link to={'/contact'}>
            <span className='text-orange-primaryColor'>{' Contact Us'} </span>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default PolicyMain;
