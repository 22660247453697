import React, { useRef } from 'react';
import { useGlobalContext } from '../AppProvider';
import { MdLocationOn, MdEmail } from 'react-icons/md';
import ContactHero from '../assets/img/contact.png';
import { FaPhoneAlt, FaPhoneSquareAlt } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import emailjs from '@emailjs/browser';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { Container } = useGlobalContext();
  const form = useRef();
  const notify = (message) => toast(message);

  const inputStyle =
    'w-full py-4 px-4 sm:text-sm border-gray-300 text-white-50 rounded-md bg-black-200 text-xl';
  const addressStyle = 'flex items-center gap-4 text-lg';

  const isValidEmail = (email) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  const handleEmailValidation = (email) => {
    const isValid = isValidEmail(email);
    return isValid;
  };

  const ErrorMessage = ({ children }) => (
    <span className='transition text-orange-primaryColor'>{children}</span>
  );

  const sendEmail = () => {
    emailjs
      .sendForm(
        'service_wu0xfpr',
        'contact_form',
        form.current,
        'htPXhFbPo_SKAVkaI'
      )
      .then(
        (result) => {
          notify('Message sent successfully.');
          form.current.reset();
        },
        (error) => {
          notify('Error. Kindly try again later or email us directly.');

          throw Error(error.text);
        }
      );
  };

  return (
    <div>
      <div className='flex flex-col'>
        <div className='flex '>
          <img
            src={ContactHero}
            className='min-h-[250px] object-cover xl:w-full max-h-[550px]'
            alt=''
            loading='lazy'
          />
        </div>
        <div className='bg-black-bodyBgColor custom-dividend-height relative items-center'>
          <Container>
            <h1
              className='absolute md:text-6xl  text-4xl font-bold -top-20 md:-top-28'
              data-aos='zoom-in'
            >
              Contact Us
            </h1>
            <div className='justify-between md:grid grid-cols-2 gap-40 py-20 flex flex-col-reverse'>
              <div>
                <form
                  ref={form}
                  onSubmit={handleSubmit(sendEmail)}
                  className='flex flex-col gap-12'
                >
                  <label htmlFor='email' className='flex flex-col gap-2'>
                    <input
                      type='text'
                      placeholder='Name'
                      className={inputStyle}
                      name='name'
                      {...register('name', {
                        required: true,
                        minLength: 3,
                        maxLength: 40,
                      })}
                    />
                    {errors.name && (
                      <ErrorMessage>Please enter your full name.</ErrorMessage>
                    )}
                  </label>

                  <label htmlFor='email' className='flex flex-col gap-2'>
                    <input
                      type='email'
                      placeholder='Email'
                      className={inputStyle}
                      name='email'
                      {...register('email', {
                        required: true,
                        validate: handleEmailValidation,
                      })}
                    />
                    {errors.email && (
                      <ErrorMessage>
                        Please enter a valid email address.
                      </ErrorMessage>
                    )}
                  </label>

                  <label htmlFor='subject' className='flex flex-col gap-2'>
                    <input
                      type='text'
                      placeholder='Subject'
                      className={inputStyle}
                      name='subject'
                      {...register('subject', { required: true })}
                    />
                  </label>

                  <textarea
                    name='message'
                    id=''
                    placeholder='Message'
                    cols='30'
                    rows='10'
                    className={inputStyle}
                    {...register('message', { required: true })}
                  ></textarea>
                  <input
                    type='submit'
                    value='Submit'
                    className={`bg-orange-primaryColor cursor-pointer text-white-50 p-4 rounded-md`}
                  />
                </form>
              </div>
              {/* CONTACT INFORMATION SECTION */}
              <div className='flex flex-col gap-4'>
                <h2 className='md:text-4xl text-3xl font-bold -top-20 md:-top-28 '>
                  Head Office
                </h2>
                <address className={addressStyle}>
                  <MdLocationOn /> 12 Cairo Crescent, Wuse 2, Abuja, Nigeria
                </address>
                <address className={addressStyle}>
                  <MdEmail />
                  <a href='mailto: support@whiteaxis.ai'>
                    support@whiteaxis.ai
                  </a>
                </address>
                <address className={addressStyle}>
                  <FaPhoneAlt />
                  <a href='tel:+2348056735181'>+234(0)8056735181</a>
                </address>

                <address className={addressStyle}>
                  <FaPhoneSquareAlt />
                  <a href='tel:+2348056735181'>+234(0)8081362116</a>
                </address>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Contact;
