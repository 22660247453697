import React from 'react';
import { useGlobalContext } from '../AppProvider';
import AboutHero from '../assets/img/about-hero.png';
import AboutSeyi from '../assets/img/about-seyi.png';
import AboutPhone from '../assets/img/about-phone.png';

const About = () => {
  //
  const { Container } = useGlobalContext();

  const ShowCaseOne = () => {
    return (
      <div className='flex flex-col'>
        <div className='flex'>
          <img
            src={AboutHero}
            className='min-h-[250px] object-cover xl:w-full max-h-[550px]'
            alt=''
          />
        </div>
        <div className='flex bg-orange-primaryColor min-h-[397px] relative p-6 '>
          <Container>
            <h1
              className='absolute md:text-6xl text-4xl font-bold -top-20 md:-top-28'
              data-aos='zoom-in'
            >
              About Us
            </h1>
            <div
              className='flex max-w-[85ch] flex-col justify-center h-full gap-8 items-center'
              data-aos='fade-right'
            >
              <h1 className='text-3xl md:text-4xl font-semibold'>
                We’re building a smart & easily accessible terminal for every
                land logistics service
              </h1>
              <p>
                The transport sector is at another stage of significant
                disruption, with new technologies, products and services
                fundamentally shifting customer expectations and opportunities.
                Once a realm dominated by private vehicles juxtaposed with
                public transit, today transportation is a much more complex and
                multilayered topic, facing new, formidable challenges but also
                standing on the brink of vast new opportunities. We have taken
                advantage and created AXIX.
              </p>
            </div>
          </Container>
        </div>
      </div>
    );
  };

  const ShowCaseTwo = () => {
    return (
      <section className='about-mv custom-dividend-height grid items-center justify-center w-full'>
        <div className='max-w-[768px] ml-[1.5rem] mr-[1.5rem]'>
          <div
            className='grid grid-cols-[0.9fr] justify-center '
            data-aos='fade-up'
          >
            <div className='grid grid-cols-[1fr_max-content_1fr]  gap-[1.5rem] text-right'>
              <div className='grid gap-2 pb-8'>
                <h3 className=' text-3xl font-semibold md:text-5xl lg:text-6xl'>
                  Vision
                </h3>
                <p className='text-md md:text-xl'>
                  Steering the wheel of global transformation from Africa, to
                  the rest of the world.
                </p>
              </div>
              <div>
                <span className='qualification-rounder'> </span>
                <span className='qualification-line'> </span>
              </div>
            </div>

            <div className='grid grid-cols-[1fr_max-content_1fr] gap-[1.5rem]'>
              <div></div>
              <div>
                <span className='qualification-rounder'> </span>
              </div>
              <div className='grid gap-2'>
                <h3 className='md:text-5xl lg:text-6xl text-3xl font-semibold'>
                  Mission
                </h3>
                <p className='text-md md:text-xl'>
                  Empowering & equipping lives with the adequate tools to thrive
                  in world that is fast-moving and constantly evolving.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const ShowCaseThree = () => {
    return (
      <section className='bg-grey-containerColor min-h-[650px]  grid items-center justify-center'>
        <Container>
          <div className='flex py-40 lg:flex-row flex-col-reverse grid-cols-2 gap-20 lg:gap-40 items-center'>
            <div className='grid gap-12 flex-1' data-aos='fade-left'>
              <h1 className='font-bold text-4xl md:text-5xl'>
                What Makes us Different.
              </h1>
              <p className='text-xl'>
                With one app, you have fast access to delivery bikes, taxis,
                ambulances, towing vehicles and moving trucks.
                <br />
                This means you can order more than one taxi, delivery bike, etc
                all at the same time. This cuts across the different logistic
                services you need. So if you need a bike, a taxi and an
                ambulance all at the same time, it is possible with AXIX!
              </p>
              {/* <button className='w-max flex items-center gap-2'>
                Read More
                <span className='mt-1'>
                  <CgArrowLongRight />
                </span>
              </button> */}
            </div>
            <div className='flex-1' data-aos='fade-right'>
              <img src={AboutSeyi} alt='' />
            </div>
          </div>
        </Container>
      </section>
    );
  };

  const ShowCaseFour = () => {
    return (
      <section className='bg-black-bodyBgColor min-h-[650px]  grid items-center justify-center'>
        <Container>
          <div className='flex py-40 lg:flex-row flex-col grid-cols-2 gap-20 lg:gap-40 items-center'>
            <div className='flex-1' data-aos='fade-right'>
              <img src={AboutPhone} alt='' />
            </div>

            <div className='grid gap-12 flex-1' data-aos='fade-left'>
              <h1 className='font-bold text-4xl md:text-5xl'>
                How We Serve You.
              </h1>
              <p className='text-xl'>
                In addition to providing all forms of land logistics in one app,
                we have innovated and created the ability of multiple requests
                per time. Clients will never get stuck and not have to wait for
                a trip or delivery service to end before you can make another
                request.
                <br />
                We help save lives by removing the transportation barrier
                between customers and the healthcare system by offering our
                ambulance service with a doctor present to deliver first aid
                service.
              </p>
            </div>
          </div>
        </Container>
      </section>
    );
  };

  return (
    <div className=''>
      <ShowCaseOne />
      <ShowCaseTwo />
      <ShowCaseThree />
      <ShowCaseFour />
    </div>
  );
};

export default About;
