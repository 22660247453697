import styled from 'styled-components';
import colors from '../../assets/styles/colors';

export const ListItem = styled.li`
  padding-inline: 1rem;
  position: relative;

  &:hover div {
    width: 100%;
  }
`;

export const Border = styled.div`
  margin-top: 10px;
  width: 0%;
  height: 4px;
  background: ${colors.orange.primaryColor};
  transition: width 250ms ease-in;
`;
