import { Avatar } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import { useGlobalContext } from '../../AppProvider';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './styles.css';

export default function LandingReviewSwiper() {
  const { Container } = useGlobalContext();

  const review = `  The AXIX app is super easy to use and effective. I can make multiple deliveries of my goods to different locations at a go.`;
  const name = `Name of Author`;
  const profession = `Profession`;

  const Article = () => (
    <article className='custom-dark-gradient-bg flex flex-col gap-4 border border-grey-50 px-6 py-8 rounded-2xl grow lg:basis-[25rem] lg:max-w-[20rem]'>
      <p>{review}</p>
      <hr />
      <div className='flex gap-4 items-center'>
        <div className='avatar'>
          <Avatar />
        </div>
        <div className='flex-1'>
          <h4 className='font-semibold'>{name}</h4>
          <span>{profession}</span>
        </div>
      </div>
    </article>
  );

  return (
    <>
      {/* Swiper js has issues with scalability. Fix or render different components depending on screen sizes.  */}
      <Container>
        <div className='hidden lg:block'>
          <Swiper
            breakpoints={{
              850: {
                slidesPerView: 3,
                spaceBetween: 50,
                slidesPerGroup: 3,
              },
            }}
            navigation={true}
            modules={[Navigation]}
            className='mySwiper'
          >
            <SwiperSlide>
              <Article></Article>
            </SwiperSlide>

            <SwiperSlide>
              <Article></Article>
            </SwiperSlide>

            <SwiperSlide>
              <Article></Article>
            </SwiperSlide>

            <SwiperSlide>
              <Article></Article>
            </SwiperSlide>
          </Swiper>
        </div>
      </Container>

      <div className='lg:hidden max-w-[20rem] md:max-w-[25rem] relative'>
        <Swiper
          pagination={true}
          navigation={true}
          modules={[Navigation, Pagination]}
          className='mySwiper'
          spaceBetween={10}
        >
          <div className='flex items-center justify-center'>
            <SwiperSlide>
              <Article></Article>
            </SwiperSlide>

            <SwiperSlide>
              <Article></Article>
            </SwiperSlide>

            <SwiperSlide>
              <Article></Article>
            </SwiperSlide>

            <SwiperSlide>
              <Article></Article>
            </SwiperSlide>
          </div>
        </Swiper>
      </div>
    </>
  );
}
