import Logo from '../../assets/img/icon.png';
import './index.css';

const BubbleAnimation = () => {
  return (
    <div className='bubbles relative gap-6 flex '>
      <div className='bubble'>
        <img src={Logo} alt='' />
      </div>
      <div className='bubble'>
        <img src={Logo} alt='' />
      </div>
      <div className='bubble'>
        {' '}
        <img src={Logo} alt='' />
      </div>
      <div className='bubble'>
        {' '}
        <img src={Logo} alt='' />
      </div>
    </div>
  );
};

export default BubbleAnimation;
