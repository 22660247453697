import { useContext, createContext, useReducer } from 'react';
import reducer from './reducer';

const initialState = {};
const AppContext = createContext();

const AppProvider = ({ children }) => {
  // eslint-disable-next-line
  const [state, dispatch] = useReducer(reducer, initialState);
  const Container = ({ children }) => {
    return (
      <div className='container mx-auto py-4 px-8 lg:px-20'>{children}</div>
    );
  };
  return (
    <AppContext.Provider value={{ ...state, Container }}>
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
