import React from 'react';
import { Link } from 'react-router-dom';
import Accordion from '../components/Accordion';
import FaqHero from '../assets/img/about-hero-prev.png';
import faqList from '../assets/data/faqs';

const FAQ = () => {
  const ShowCaseOne = () => {
    return (
      <div className='flex flex-col'>
        <div className='flex'>
          <img
            src={FaqHero}
            className='min-h-[250px] xl:w-full max-h-[550px] object-cover'
            alt=''
          />
        </div>
        <div className='flex relative'>
          <div className='container mx-auto px-8 lg:px-20'>
            <h1 className='absolute md:text-6xl text-4xl font-bold -top-20 md:-top-28'>
              FAQ
            </h1>
          </div>
        </div>
      </div>
    );
  };

  const ShowCaseTwo = () => {
    return (
      <div className='custom-faq-bg-lg min-h-[52rem] flex items-center justify-center py-32'>
        <div className='bg-grey-100 lg:w-1/2 w-5/6 p-8 rounded-xl flex flex-col gap-12'>
          <h4 className='text-2xl font-bold '>Frequently Asked Questions</h4>
          <div className='flex flex-col gap-6 '>
            {faqList.map((faq) => (
              <Accordion key={faq.id} {...faq} />
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <ShowCaseOne />
      <ShowCaseTwo />
      <div className='flex flex-col gap-12 items-center my-20 '>
        {/* add link to playstore or appstore */}
        <Link to='/'>
          <span className='p-4 bg-orange-primaryColor rounded '>
            {'Sign Up with AXIX'}
          </span>
        </Link>
        <p className='font-bold text-lg'>
          {'Got questions or concerns? '}
          <Link to={'/contact'}>
            <span className='text-orange-primaryColor'>{' Contact Us'} </span>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default FAQ;
