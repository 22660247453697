import React from 'react';
import DownloadSection from './DownloadSection';
import Hero from './Hero';
import OrderRides from './OrderRides';
import Reviews from './Reviews';
import Services from './Services';
import UniquePortray from './UniquePortray';

const Home = () => {
  return (
    <div>
      <Hero />
      <OrderRides />
      <UniquePortray />
      <Services />
      <Reviews />
      <DownloadSection />
      <div className='h-36 md:hidden '></div>
    </div>
  );
};

export default Home;
