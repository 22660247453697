import PlayStoreImg from "../../assets/img/play-store.png";
import AppStoreImg from "../../assets/img/app-store.png";

const AppleLink = () => (
  <div className="flex gap-6 my-2 hover:scale-[1.1] transition-all">
    <a href="https://apps.apple.com/us/app/axix/id6447292157">
      <img src={AppStoreImg} alt="link_to_app_store" />
    </a>
  </div>
);

const PlayLink = () => (
  <div className="flex gap-6 my-2 hover:scale-[1.1] transition-all">
    <a href="https://play.google.com/store/apps/details?id=app.axix.client">
      <img src={PlayStoreImg} alt="link_to_play_store" />
    </a>
  </div>
);

const DownloadApp = () => {
  const MobileDetect = require("mobile-detect");
  const type = new MobileDetect(window.navigator.userAgent);

  if (type.os() === "iOS") {
    return <AppleLink />;
  }

  if (type.os() === "AndroidOS") {
    return <PlayLink />;
  }

  return (
    <div className="flex gap-6 my-2">
      <PlayLink />
      <AppleLink />
    </div>
  );
};

export default DownloadApp;
