import { memo } from 'react';
import { useEffect, useState } from 'react';
import { BsArrowUp } from 'react-icons/bs';
import './backToTop.css';

const BackToTopButton = () => {
  // The back-to-top button is hidden at the beginning
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // for smoothly scrolling
    });
  };

  return (
    <>
      {showButton && (
        <button
          onClick={scrollToTop}
          className='text-2xl bg-orange-primaryColor hover:bg-orange-secondaryColor md:text-4xl back-to-top'
        >
          <BsArrowUp />
        </button>
      )}
    </>
  );
};

const BackToTopButtonMemo = memo(BackToTopButton);

export default BackToTopButtonMemo;
