import { MdWifiTethering, MdCheckCircle } from 'react-icons/md';
import { FaLocationArrow } from 'react-icons/fa';
import { TiLocationArrow } from 'react-icons/ti';
import { useGlobalContext } from '../../AppProvider';

export default function Benefits() {
  const { Container } = useGlobalContext();
  return (
    <div className='bg-orange-primaryColor min-h-[30rem] p-12 md:px-0 flex'>
      <Container>
        <div className='flex flex-col justify-center h-full gap-20 '>
          <h1 className='font-bold text-3xl md:text-4xl' data-aos='fade-right'>
            Earn while you drive.
          </h1>

          <div className='flex flex-wrap lg:gap-32 gap-12 justify-between '>
            <div
              className='text-lg flex flex-col basis-[200px] grow w-full gap-4'
              data-aos='fade-down'
            >
              <p className='text-4xl '>
                <MdWifiTethering />
              </p>
              <p className='text-3xl font-semibold'>Go Online</p>
              <p>Sign in to your AXIX account and start earning money.</p>
            </div>

            <div
              className='text-lg flex flex-col basis-[200px] grow gap-4'
              data-aos='fade-down'
            >
              <p className='text-4xl '>
                <MdCheckCircle />
              </p>
              <p className='text-3xl font-semibold'>Accept Request</p>
              <p>
                The AXIX app is designed to help you receive and accept client
                requests with ease.
              </p>
            </div>

            <div
              className='text-lg flex flex-col basis-[200px] grow gap-4'
              data-aos='fade-down'
            >
              <p className='text-4xl '>
                <TiLocationArrow />
              </p>
              <p className='text-3xl font-semibold'>Drive To Location</p>
              <p>
                AXIX maps are updated in real time, making it easy for you to
                locate & pick-up your clients.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
