import { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import Logo from '../../assets/img/logo.png';
import { Border, ListItem } from './styles';

const Header = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [width, setWidth] = useState(0);
  const [routeLocation, setRouteLocation] = useState(false);
  let location = useLocation();

  // Functionality for scrolling
  const navBar = useRef(null);
  let lastScrollY = window.scrollY;
  window.addEventListener('scroll', () => {
    if (lastScrollY < window.scrollY) {
      navBar.current?.classList.add('nav-bar-hide-on-scroll');
    } else {
      navBar.current?.classList.remove('nav-bar-hide-on-scroll');
    }
    lastScrollY = window.scrollY;
  });

  useEffect(() => {
    navBar.current.classList.remove('nav-bar-hide-on-scroll');
  }, []);

  // Functionality to know the active route
  useEffect(() => {
    setRouteLocation(location.pathname);
    window.scrollTo(0, 0);
  }, [location, location.pathname]);

  if (width > 768 && navbarOpen) {
    setNavbarOpen(false);
  }

  window.addEventListener('resize', (e) => setWidth(e.target.innerWidth));

  const Navigation = ({ fixed }) => {
    const Li = ({ page }) => {
      // checks for the current page using the page attribute and window location. Set activeRoute to true or false based on the current page.
      const activeRoute =
        (routeLocation === `/` && page === 'home') ||
        routeLocation === `/${page}`;

      return (
        <ListItem>
          <Link
            to={`/${page === 'home' ? '' : page}`}
            className={`'ml-2 px-1 py-4 text-lg uppercase font-semibold text-white-50 hover:opacity-75 md:py-3 ' ${
              activeRoute && 'border-orange-primaryColor border-b-4'
            }`}
            onClick={() => {
              setNavbarOpen(false);
            }}
          >
            {page}
          </Link>
          <Border />
        </ListItem>
      );
    };

    return (
      <>
        <nav className='container mx-auto relative flex flex-wrap items-center px-2 py-3 bg-grey-containerColor md:mb-3'>
          <div
            className={
              'w-full px-4 flex items-center justify-between py-3' +
              (navbarOpen ? ' flex-col mb-32 ' : ' flex-row ')
            }
          >
            <div className='w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start mt-1'>
              <Link to={'/'}>
                <img
                  src={Logo}
                  alt='Axix Logo'
                  className='lg:w-4/6 scale-75  w-28 '
                />
              </Link>

              <button
                className='text-white-50 cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent bg-transparent block md:hidden outline-none focus:outline-none'
                type='button'
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                {navbarOpen ? <FaTimes /> : <FaBars />}
              </button>
            </div>

            <div
              className={
                'md:flex items-center ' +
                (navbarOpen
                  ? 'flex p-4 gap-4 mt-4 items-center justify-center text-lg'
                  : 'hidden ')
              }
              id='example-navbar-danger'
            >
              <ul
                className={
                  'flex flex-col md:flex-row list-none md:ml-auto' +
                  (navbarOpen && ' flex-start mt-20 gap-12 items-center')
                }
              >
                {<Li page={'home'} />}
                {<Li page={'about'} />}
                {<Li page={'drive'} />}
                {<Li page={'contact'} />}
                {<Li page={'faq'} />}
              </ul>
            </div>
          </div>
        </nav>
      </>
    );
  };

  return (
    <>
      <div
        className='fixed w-screen top-0 z-50 transition-transform'
        ref={navBar}
      >
        <header className=' bg-grey-containerColor py-2 lg:px-20 '>
          <Navigation />
        </header>
      </div>
    </>
  );
};

export default Header;
