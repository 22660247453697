import PlayStoreImg from "../../assets/img/play-store.png";
import AppStoreImg from "../../assets/img/app-store.png";

const PartnerPlayLink = () => (
  <div className="flex gap-6 my-2 hover:scale-[1.1] transition-all">
    <a href="https://play.google.com/store/apps/details?id=app.axix.partner">
      <img src={PlayStoreImg} alt="link_to_play_store" />
    </a>
  </div>
);

const PartnerAppleLink = () => (
  <div className="flex gap-6 my-2 hover:scale-[1.1] transition-all">
    <a href="https://apps.apple.com/ng/app/axix-partner/id6446205527">
      <img src={AppStoreImg} alt="link_to_app_store" />
    </a>
  </div>
);

const DownloadAppPartner = () => {
  const MobileDetect = require("mobile-detect");
  const type = new MobileDetect(window.navigator.userAgent);

  if (type.os() === "iOS") {
    return <PartnerAppleLink />;
  }

  if (type.os() === "AndroidOS") {
    return <PartnerPlayLink />;
  }

  return (
    <div className="flex gap-6 my-2">
      <PartnerPlayLink />
      <PartnerAppleLink />
    </div>
  );
};

export default DownloadAppPartner;
