import { useGlobalContext } from '../../AppProvider';
import Swipee from '../../components/LandingReviewSwiper';

export default function Reviews() {
  const { Container } = useGlobalContext();

  return (
    <div className='min-h-[650px] bg-black-bodyBgColor flex items-center my-12'>
      <Container>
        <div className='flex flex-col gap-16 items-center'>
          <h1
            className='text-3xl font-bold text-center lg:text-4xl max-w-[24ch]'
            data-aos='zoom-in'
          >
            Take a moment to hear from trusted voices
          </h1>
          <div className='flex-start' data-aos='zoom-in'>
            <Swipee />
          </div>
        </div>
      </Container>
    </div>
  );
}
