import React from 'react';
import { Link } from 'react-router-dom';
import { ErrorSvg } from '../utils';

const Error = () => {
  return (
    <div className='flex items-center lg:flex-row flex-col-reverse py-12'>
      <p className='lg:transform-none scale-75'>
        <ErrorSvg />
      </p>
      <div className='text-center'>
        <h1 className='md:text-[16rem] text-[10rem]'>404</h1>
        <h5 className='text-2xl'>Page Not Found</h5>
        <Link to={'/'}>
          {' '}
          <button className='m-8 px-6 py-4 bg-orange-primaryColor rounded font-bold'>
            GO BACK TO HOME
          </button>{' '}
        </Link>
      </div>
    </div>
  );
};

export default Error;
