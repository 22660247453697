import React from 'react';
import { Link } from 'react-router-dom';
import {
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
} from 'react-icons/fa';
import { SocialLinks } from '../../utils';
import Logo from '../../assets/img/logo.png';
import TermsOfService from '../../assets/pdfs/TERMS OF SERVICE.pdf';
import { CoverButton } from '../Buttons';

const Footer = () => {
  return (
    <footer className='justify-self-end bg-grey-containerColor py-20 px-20 flex flex-col gap-8 md:h-26'>
      <div className='flex flex-col justify-around gap-6 items-center lg:items-start lg:gap-0 lg:flex-row'>
        <div>
          <img
            src={Logo}
            alt='AXIX Logo'
            className='lg:w-4/6 scale-75  w-28 '
          />
        </div>
        <div>
          <ul className='flex flex-col md:flex-row gap-8 items-center'>
            <li className=' hover:text-orange-primaryColor transition'>
              <Link to='/policies'>Privacy Policy</Link>
            </li>
            <li className=' hover:text-orange-primaryColor transition'>
              <a href={TermsOfService} target='_blank' rel='noreferrer'>
                Terms of service
              </a>
            </li>
            <li className=' hover:text-orange-primaryColor transition'>
              <Link to='/'>Cookie Policy</Link>
            </li>
            <li className=' hover:text-orange-primaryColor transition'>
              <Link to='/faq'>Help/FAQ</Link>
            </li>
          </ul>
        </div>
        <div className='flex gap-4  text-black-bodyBgColor '>
          <CoverButton
            as={'a'}
            href='https://twitter.com/axixapp'
            target='_blank'
          >
            <FaTwitter />
          </CoverButton>
          <CoverButton
            as={'a'}
            href='https://www.facebook.com/axixapp/'
            target='_blank'
          >
            <FaFacebookF />
          </CoverButton>
          <CoverButton
            as={'a'}
            href='https://www.linkedin.com/company/white-axis-limited/'
            target='_blank'
          >
            <FaLinkedinIn />
          </CoverButton>
          <CoverButton
            as={'a'}
            href='https://www.instagram.com/axixapp/'
            target='_blank'
          >
            <FaInstagram />
          </CoverButton>
        </div>
      </div>
      <p className='text-sm text-center opacity-70'>
        Copyright © 2022 White Axis Limited. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
