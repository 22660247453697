import { LazyLoadImage } from 'react-lazy-load-image-component';
import Landing1 from '../../assets/img/landing-showcase-1-woman.png';
import { useGlobalContext } from '../../AppProvider';
import { PrimaryButton } from '../../components/Buttons';
import { css } from 'styled-components';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Hero() {
  const { Container } = useGlobalContext();

  return (
    <Container>
      <div className='custom-sub-height flex flex-col gap-8 justify-center items-center mt-7 md:m-0 md:flex-row lg:flex-row'>
        <div
          className='flex-1 flex flex-col gap-6 items-center md:items-start'
          data-aos='fade-up-right'
        >
          <h1 className='text-5xl font-bold text-center  md:text-left lg:text-6xl '>
            The <span className='custom-gradient-text'>better way</span> to move
          </h1>
          <p className='text-xl text-center md:text-left '>
            {
              ' The AXIX app connects you to the perfect land logistics services, closest to you.  '
            }
          </p>

          <PrimaryButton
            as={'a'}
            href='#download_app'
            css={css`
              width: 50%;
            `}
          >
            Let's go
          </PrimaryButton>
        </div>

        <div className='flex-1 ' data-aos='fade-up-left'>
          <div className='custom-box-shadow rounded-3xl scale-90 w-80 md:m-3 md:w-full'>
            <LazyLoadImage
              effect='blur'
              src={Landing1}
              alt='woman riding a car'
            />
          </div>
        </div>
      </div>
    </Container>
  );
}
