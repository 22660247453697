import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import Accordion from '../components/Accordion';
import PrivacyPolicy from '../assets/pdfs/PRIVACY POLICY.pdf';
import policiesList from '../assets/data/policies';

const MappedPolicies = () => {
  return (
    <div className='custom-faq-bg-lg min-h-[52rem] flex items-center justify-center pt-6 pb-32 flex-col'>
      <h1 className='lg:w-1/2 w-5/6 font-bold lg:text-4xl text-3xl text-left'>
        Policies
      </h1>
      <div className='bg-grey-100 lg:w-1/2 w-5/6 p-8 rounded-xl flex gap-12  my-20 '>
        <a
          href={PrivacyPolicy}
          target='_blank'
          rel='noreferrer'
          className='hover:text-orange-primaryColor text-2xl font-bold flex items-center gap-4  '
        >
          <Link to={'/privacy/policy'}>
            <span className='text-orange-primaryColor'>{' Privacy Policy'} </span>
          </Link>
          {/* Privacy Policy
          <button>
            <BsArrowRight />
          </button> */}
        </a>
      </div>

      <div className='bg-grey-100 lg:w-1/2 w-5/6 p-8 rounded-xl flex flex-col gap-12'>
        <h4 className='text-2xl font-bold '>Additional Policies</h4>
        <p>
          To find the document applicable to you in respect to policies and how
          Axis collects, handles and processes data of its customers and
          visitors, check the section below. Also check the section below for
          other policies.
        </p>
        <div className='flex flex-col gap-6 '>
          {policiesList.map((policy) => (
            <Accordion key={policy.id} {...policy} />
          ))}
        </div>
      </div>
    </div>
  );
};

const Policy = () => {
  return (
    <>
      <div className='my-8 bg-grey-containerColo p-12 '></div>
      <MappedPolicies />
    </>
  );
};

export default Policy;
