import * as React from 'react';
import { styled } from '@mui/material/styles';
import { FaPlus, FaMinus } from 'react-icons/fa';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { FiDownload } from 'react-icons/fi';
import { Icon } from '@mui/material';

const Accordion = styled((props) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square={false}
    sx={{ border: 4 }}
    {...props}
  />
))(({ theme }) => ({
  border: `1px solid black`,
  backgroundColor: `#FFE7CF`,
  borderLeft: '9px solid #f69100',

  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&.MuiAccordion-rounded': {
    borderRadius: '16px',
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      props.expanded ? (
        <FaMinus color={'black'} sx={{ fontSize: '0.9rem', color: 'black' }} />
      ) : (
        <FaPlus color={'white'} sx={{ fontSize: '0.9rem', color: 'white' }} />
      )
    }
    {...props}
  />
))(({ theme, expanded }) => ({
  // backgroundColor:
  //   theme.palette.mode === 'dark'
  //     ? 'rgba(255, 255, 255, .05)'
  //     : 'rgba(0, 0, 0, .03)',

  backgroundColor: `${expanded ? '#FFE7CF' : '#141414'}`,
  color: `${!expanded && 'white'}`,
  padding: theme.spacing(2),
  flexDirection: 'row-reverse',

  '&.MuiAccordionSummary-root': {
    borderRadius: '12px',
  },

  '& .MuiAccordionSummary-content.Mui-expanded .MuiTypography-root': {
    fontWeight: '700',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(3),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions({ id, question, answer, link }) {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Accordion
        expanded={expanded === `panel${id}`}
        onChange={handleChange(`panel${id}`)}
      >
        <AccordionSummary
          aria-controls='panel1d-content'
          id='panel1d-header'
          expanded={expanded === `panel${id}`}
        >
          <Typography>{question}</Typography>{' '}
          {link && (
            <Icon className='ml-auto hover:text-orange-primaryColor'>
              <a href={link} download>
                {console.log(link)}
                <FiDownload />
              </a>
            </Icon>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{answer}</Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
