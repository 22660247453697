import Phone from "../../assets/img/phone.png";
import DownloadApp from "../../components/DownloadApp";
import { useGlobalContext } from "../../AppProvider";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function OrderRides() {
  const { Container } = useGlobalContext();

  return (
    <div className="flex h-max items-center bg-inherit m-6 md:m-0 md:h-[950px]  ">
      <div className="h-max w-full flex items-center custom-landing-3-background md:h-1/2 ">
        <Container>
          <div className="flex items-center justify-between h-5/6 gap-4 flex-col m-3 md:flex-row">
            <div
              className=" gap-4 flex flex-1 flex-col md:gap-2 "
              data-aos="fade-down-right"
            >
              <h1 className="text-4xl font-bold lg:text-4xl ">
                Order multiple rides on the go
              </h1>
              <p>
                You can order multiple rides for your friends and family, and
                for all your deliveries.
              </p>
              <p>Download the app today</p>

              <DownloadApp />
            </div>

            <div className="flex flex-1" data-aos="flip-right">
              <div className="w-96 md:w-full">
                <LazyLoadImage effect="blur" src={Phone} alt="" />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
